import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import MainSectionZakaznickaZona from "../components/zakaznicka-zona/mainSection.js";

import "../styles/programovanie-na-mieru.scss";
import ContactUs from "../components/contactUs";
import ProgramovanieSolutionBox from "../components/programovanie-na-mieru/programovanieSolutionBox";
import ZonaFaqQuestion from "../components/zakaznicka-zona/zonaFaqQuestion.js";
import SliderSectionZona from "../components/zakaznicka-zona/sliderSection.js";
import References from "../components/homepage/references.js";
import ZonaClankySlider from "../components/zakaznicka-zona/zonaClankySlider";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";

import stretnutieImg from "../images/outsourcing-it/it-outsourcing-freelance-prenajom-stretnutie.jpg";
import pristupImg from "../images/outsourcing-it/it-projekt-outsourcing-na-mieru.jpg";
import startImg from "../images/outsourcing-it/outsourcing-it-a-projektov-vas-account-manazer.jpg";
import monitorivanieImg from "../images/outsourcing-it/outsourcing-softveroveho-projektu-monitoring-a-adaptacia.jpg";

import kvalitaIcon from "../images/outsourcing-it/it-outsourcing-kvalita.svg";
import profesionalitaIcon from "../images/outsourcing-it/it-outsourcing-profesionalita-spolahlivost.svg";
import praxIcon from "../images/outsourcing-it/it-outsourcing-prax-odbornost.svg";
import systemyIcon from "../images/outsourcing-it/it-software-development-outsourcing-systemy.svg";
import referencieIcon from "../images/outsourcing-it/it-outsourcing-development-referencie-kvalita.svg";
import komunikaciaIcon from "../images/outsourcing-it/software-development-outsourcing-komunikacia.svg";
import partnerstvoIcon from "../images/outsourcing-it/it-softver-development-outsourcing-partnerstvo.svg";

//import zonesColage from "../images/zakaznicka-zona/okna.jpg";

export const pageQuery = graphql`
  query {
    articles: wpCategory(slug: { eq: "webdizajn-web-a-portaly" }) {
      posts {
        nodes {
          title
          excerpt
          slug
          featuredImage {
            node {
              altText
              title
              gatsbyImage(width: 1920)
              sourceUrl
            }
          }
        }
      }
    }
    mysqlProjects(url: { eq: "plusim" }) {
      url
      real_url
      client
      hashtag
      project_description
      case_study_title
      case_study_rok
      case_study_sluzby
      case_study_vyzva
      case_study_proces
      case_study_riesenie
      ogDescription
    }
    files: allFile(
      filter: {
        relativeDirectory: { eq: "plusim" }
        sourceInstanceName: { eq: "images-projects" }
      }
    ) {
      nodes {
        name
        publicURL
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    projekty: allMysqlProjects(sort: { fields: case_study_rok, order: DESC }) {
      nodes {
        url
        client
        case_study_description
      }
    }
    logosWhite: allFile(
      filter: {
        name: { eq: "logo-white" }
        sourceInstanceName: { eq: "images-projects" }
      }
    ) {
      nodes {
        name
        relativeDirectory
        publicURL
      }
    }
  }
`;

const Outsourcing = (pageQuery) => {
  const images = {};
  pageQuery.data.files.nodes.map((node) => {
    let index = node.name;
    images[index] = node;
  });

  return (
    <Layout pageName="programovanie-na-mieru-main">
      <SEO
        title="Outsourcing projektov a IT kapacít"
        description="Robíme IT outsourcing kapacít, zabezpečujúc efektívnu a spoľahlivú podporu pre vaše technologické potreby. Súčasne ponúkame špecializovaný softvér development outsourcing, ktorý vám pomôže realizovať vaše najambicióznejšie projekty."
      />
      <MainSectionZakaznickaZona
        heading="Outsourcing projektov a IT kapacít"
        id="lg"
        textHtml={
          <>
            <b>Robíme IT outsourcing kapacít</b>, zabezpečujúc efektívnu a
            spoľahlivú podporu pre vaše technologické potreby. Súčasne ponúkame
            špecializovaný <b>softvér development outsourcing</b>, ktorý vám
            pomôže realizovať vaše najambicióznejšie projekty.
          </>
        }
      />
      <section id="what-is-client-zone">
        <ProgramovanieSolutionBox
          img={4}
          heading="Odbornosť a skúsenosti"
          text={
            <>
              <b>Zamerajte sa na svoje hlavné obchodné aktivity</b> a nechajte
              nás postarať sa o <b>komplexný vývoj softvéru</b> alebo
              poskytnutie služby <b>dodatočných programátorských kapacít</b>.
              Pridáme hodnotu vašim projektom cez našu{" "}
              <b>špecializovanú expertízu a skúsenosti</b>.
            </>
          }
        />
      </section>

      {/*<section id="client-zone-colage">
        <img
          className="img"
          src={zonesColage}
          alt="Zákaznícke zóny"
          
        />
      </section>*/}

      <section className="section-blog-second">
        <div className="banner-blog">
          <h2>
            Venujeme sa <b>vývoju softvéru</b> už od roku 2014 a máme za sebou{" "}
            <b>desiatky úspešných projektov</b>. S našimi 9-ročnými skúsenosťami
            sme nielen realizovali projekty na domácom trhu, ale aj v{" "}
            <b>zahraničí</b>. Počas týchto rokov sme nadobudli bohaté
            skúsenosti, ktoré nám umožňujú prinášať vám vynikajúce riešenia a
            hodnotu.
          </h2>
        </div>
      </section>
      <section id="case-study-info">
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={kvalitaIcon}
                alt="IT outsourcing kvalita"
                title="IT outsourcing kvalita"
              />
            </div>
          </div>
          <div>
            <h3>
              Kvalita{" "}
              <StaticImage
                className="img"
                src="../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Naše riešenia sú výsledkom dôslednej práce a záväzku k
              dokonalosti, čím zaručujeme najvyššiu kvalitu všetkých nami
              dodávaných služieb.
            </p>
          </div>
        </div>
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={profesionalitaIcon}
                alt="IT outsourcing profesionalita spolahlivosť"
                title="IT outsourcing profesionalita spolahlivosť"
              />
            </div>
          </div>
          <div>
            <h3>
              Profesionalita{" "}
              <StaticImage
                className="img"
                src="../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Náš tím pozostáva z odborníkov, ktorí sú zanietení svojim
              remeslom, čo zabezpečuje, že každý projekt je riešený s najväčším
              možným odhodlaním a profesionalitou.
            </p>
          </div>
        </div>
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={praxIcon}
                alt="IT outsourcing prax odbornosť"
                title="IT outsourcing prax odbornosť"
              />
            </div>
          </div>
          <div>
            <h3>
              Prax{" "}
              <StaticImage
                className="img"
                src="../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              S rokmi skúseností v oblasti IT a softvérového vývoja sme získali
              hlboké znalosti a zručnosti, ktoré uplatňujeme v praxi pre váš
              úspech.
            </p>
          </div>
        </div>
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={systemyIcon}
                alt="IT software development outsourcing systémy"
                title="IT software development outsourcing systémy"
              />
            </div>
          </div>
          <div>
            <h3>
              Systémy{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Využívame najnovšie a najefektívnejšie systémy na riadenie
              projektov a vývoj softvéru, čím zabezpečujeme plynulosť a
              efektivitu práce.
            </p>
          </div>
        </div>

        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={referencieIcon}
                alt="IT outsourcing development referencie kvalita"
                title="IT outsourcing development referencie kvalita"
              />
            </div>
          </div>
          <div>
            <h3>
              Referencie{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Máme za sebou množstvo úspešných projektov a spokojných klientov,
              ktorí môžu potvrdiť našu kvalitu a odbornosť. Vaša spokojnosť je
              naším najlepším odporúčaním.
            </p>
          </div>
        </div>

        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={komunikaciaIcon}
                alt="Software development outsourcing komunikácia"
                title="Software development outsourcing komunikácia"
              />
            </div>
          </div>
          <div>
            <h3>
              Komunikácia{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Uznávame dôležitosť transparentnej a efektívnej komunikácie. S
              nami budete vždy informovaní o priebehu projektu a vaše otázky a
              podnety sú vždy vítané.
            </p>
          </div>
        </div>

        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={partnerstvoIcon}
                alt="IT softvér development outsourcing partnerstvo"
                title="IT softvér development outsourcing partnerstvo"
              />
            </div>
          </div>
          <div>
            <h3>
              Partnerstvo{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Veríme v dlhodobé vzťahy a partnerstvo s našimi klientami.
              Spoločne s vami budujeme mosty k úspechu a rastu vášho podnikania.
            </p>
          </div>
        </div>
      </section>
      <section className="section-blog">
        <div className="banner-blog">
          <p>
            Ste pripravení na spoluprácu s odborníkmi, ktorí vás povedú k
            úspechu? Kontaktujte nás ešte dnes a začneme spoločne budovať váš
            budúci projekt!
          </p>
        </div>
      </section>
      <section id="online-business-solutions">
        <h2>Zaujíma Vás ako prebieha spolupráca?</h2>
        <div className="solution-box loaded">
          <img
            className="img solution-img"
            src={stretnutieImg}
            alt="IT outsourcing freelance prenájom stretnutie"
            title="IT outsourcing freelance prenájom stretnutie"
          />
          <div className="solution-description">
            <h3>Osobné stretnutie</h3>
            <p>
              Dôležitosť spolupráce nemožno podceňovať. Kvalitné procesy a
              správna voľba technológií vedú k úspechu. Stretneme sa, preskúmame
              potreby, navrhneme riešenie, a skúšobne to otestujeme. Spolu
              diskutujeme o vašich predstavách, technických a procesných
              požiadavkách. Ujasníme si podmienky a termín začiatku partnerstva.
            </p>
            {/*<br />
            <p className="bold">
              Prvé stretnutie je na nás a trvá 1 – 2 hodiny
            </p>*/}
          </div>
        </div>

        <div className="solution-box loaded">
          <div className="solution-description">
            <h3>Individuálny prístup</h3>
            <p>
              Po našom stretnutí zostavíme pre vás tím skúsených IT
              špecialistov, ktorý bude spoľahlivý, konzistentný a hlavne
              produktívny. Budeme pokrývať všetky stránky vášho projektu a
              nezriedka prichádzame s riešeniami, ktoré prekonávajú pôvodné
              očakávania. Stanovíme podmienky a určíme začiatok našej
              spolupráce.
            </p>
          </div>
          <img
            className="img solution-img"
            src={pristupImg}
            alt="IT projekt outsourcing na mieru"
            title="IT projekt outsourcing na mieru"
          />
        </div>

        <div className="solution-box loaded">
          <img
            className="img solution-img"
            src={startImg}
            alt="Outsourcing IT a projektov váš account manažér"
            title="Outsourcing IT a projektov váš account manažér"
          />
          <div className="solution-description">
            <h3>Aktívny štart</h3>
            <p>
              Predstavíme Vám vášho kontaktného manažéra - spoľahlivú, rýchlu
              osobu, ktorá vám bude počas projektu k dispozícii. Naše tímy začnú
              realizovať vašu víziu pod dohľadom manažéra projektu. V prípade
              potreby sa stanete sa integrálnou súčasťou nášho dynamického tímu.
            </p>
          </div>
        </div>

        <div className="solution-box loaded">
          <div className="solution-description">
            <h3>Monitorovanie a adaptácia</h3>
            <p>
              Očakávajte jasnosť, pravidelné aktualizácie a úplné zastúpenie vo
              vašom projekte. Budeme vás pravidelne informovať o priebehu a
              podľa vašich potrieb upravovať náš prístup. Sústredená spätná
              väzba nám následne pomôže spoločne dosiahnuť úspešný štart vášho
              projektu.
            </p>
          </div>
          <img
            className="img solution-img"
            src={monitorivanieImg}
            alt="Outsourcing softvérového projektu monitoring a adaptácia"
            title="Outsourcing softvérového projektu monitoring a adaptácia"
          />
        </div>
      </section>

      <References />

      <ZonaClankySlider clanky={pageQuery.data.articles} />

      <section id="zonaFaq">
        <h2>
          Často kladené otázky
          <StaticImage
            className="img"
            src="../../images/new-web/arrow.svg"
            alt="Šípka vpravo"
            placeholder="blurred"
          />
        </h2>

        <p>
          Najčastejšie otázky k poskytovaniu IT outsourcingu, outsourcingu
          softvérového vývoja alebo služby freelancerov sa najčastejšie
          stretávam s týmito otázkami:
        </p>
        <br />
        <br />

        <ZonaFaqQuestion
          question="Aké sú hlavné výhody IT outsourcingu?"
          answer={
            <>
              Hlavné výhody zahŕňajú nákladovú efektívnosť, prístup k
              špecializovaným zručnostiam a technológiám, flexibility v zdrojoch
              a rýchlejšie nasadenie projektov.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Ako zabezpečujete dáta a informácie mojej firmy?"
          answer={
            <>
              Vaše dáta sú pre nás prioritou. Používame špičkové bezpečnostné
              protokoly, šifrovanie a postupy na ochranu dát. Okrem toho môžeme
              nastaviť dodatočné bezpečnostné opatrenia podľa vašich potrieb a
              samozrejme riadime sa predpísanými GDPR.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Aký je váš proces výberu a skríningu vývojárov či freelancerov?"
          answer={
            <>
              Naši vývojári a freelanceri prechádzajú prísnym výberovým
              procesom, ktorý zahŕňa technické testy, rozhovory a overovanie
              referencií. Týmto spôsobom zabezpečujeme, že sú nielen odborníkmi
              vo svojom obore, ale aj spoľahlivými partnermi.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Ako komunikujete počas projektu?"
          answer={
            <>
              Vytvárame transparentné komunikačné kanály medzi vami a našim
              tímom. Môžete očakávať pravidelné aktualizácie, správy o stave
              projektu a prístup k nástrojom pre sledovanie pokroku.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Čo ak nie som spokojný s prácou vývojára alebo freelancera?"
          answer={
            <>
              Vaša spokojnosť je pre nás kľúčová. V prípade nejakých problémov
              alebo nespokojnosti nás okamžite informujte a budeme pracovať na
              riešení alebo, ak je to potrebné, na náhrade konkrétnej osoby v
              projekte.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Ako je stanovená cena za vaše služby?"
          answer={
            <>
              Cena závisí od mnohých faktorov vrátane rozsahu projektu, počtu
              ľudí, požadovaných technológií a doby trvania projektu. Radi vám
              poskytneme konkrétny odhad po dôkladnej analýze vašich potrieb.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Môžete pracovať v rámci môjho časového pásma alebo sa prispôsobiť môjmu pracovnému harmonogramu?"
          answer={
            <>
              Áno, sme flexibilní a dokážeme sa prispôsobiť vášmu časovému pásmu
              alebo pracovným hodinám, aby sme maximalizovali efektívnosť
              komunikácie a spolupráce.
            </>
          }
        />
      </section>
      <ContactUs
        text={<>Máte záujem o outsourcing projektov a IT kapacít?</>}
        button="Napíšte nám e-mail"
      />
    </Layout>
  );
};

export default Outsourcing;
